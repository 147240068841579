// About.js
import React from 'react';
import "./About.css";
import about from "./About-backg.jpg";

function About() {
  return (
    <section className="about-section" style={{ backgroundImage: `url(${about})` }}>
      <div className="container">
        <h2 className="about-heading">About Us</h2>
        <div className="card">
          <div className="card-body">
            <p className='about-paragraph'>Pantheon Con. was established in 2023 by two innovative individuals with nothing but a drive for excellence and passion for producing and providing high quality services in the business industry. 
Pantheon is a 100% black owned and directed company which offers a complete range of plumbing and sanitation services to cater to clients needs and exceed industry standards. 
We aim to offer full transparency with our clients, pursue and attain internationally recognized qualifications within the building services industry, but most importantly develop to a point of claiming the title of industry leader, thus making Pantheon more than just a business but should be portrayed as an entity which sets an example of how companies should strive to provide and archive top-notch  workmanship and ethical conduct. 
</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About;