// Services.js
// Services.js
import React from 'react';
import './Services.css';
import contact from "./Contact-bg.jpg";

export default function Services() {
  return (
    <div className="service-container" style={{ backgroundImage: `url(${contact})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
      <div className="container d-flex flex-column align-items-center">
        <h2 className="service-heading mt-5">Our Services</h2>
        <div className="row">
          <div className="col-md-6">
            <div className="service">
              <h3>General Plumbing</h3>
              <p>To design, install and maintain healthy consumer-friendly water supply.</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="service">
              <h3>Blocked Drains</h3>
              <p>To design, as well as the installation and maintenance of waste removal systems to combat waste water issues, control sewers, and the conversion of water.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="service">
              <h3>Geyser Maintenance & Replacement</h3>
              <p>Whether it's fixing a malfunctioning geyser or upgrading to a more efficient model, our experts ensure top-notch service, timely maintenance and reliable solutions.</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="service">
              <h3>Bathroom Renovations</h3>
              <p>Transform your bathroom into a stylish and functional space with our expert bathroom renovation services. Whether you're looking to update fixtures or completely remodel your bathroom, our skilled plumbers and contractors will bring your vision to life.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="service">
              <h3>Plumbing COC's (Certificate of Compliance)</h3>
              <p>Ensure your property meets plumbing regulatory standards with our Plumbing COC services. Our certified plumbers will inspect your plumbing system and issue a Certificate of Compliance, giving you peace of mind and ensuring your property is legally compliant.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
