import React, { useState } from 'react';
import contact from "./About-backg.jpg"; // Import your background image file
import './Contact.css';
import emailjs from 'emailjs-com';

export default function Contact2() {
  const [successMessage, setSuccessMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_zysguah', 'template_k13h0s5', e.target, 'tPvkCW6sF9p_reaf_')
      .then(
        (result) => {
          console.log('SUCCESS!', result.text);
          setSuccessMessage("Your message has been sent successfully!");
        },
        (error) => {
          console.log('FAILED...', error.text);
          setSuccessMessage("Oops! Something went wrong. Please try again later.");
        }
      );

    e.target.reset();
  };

  return (
    <div className='contact-section' style={{ backgroundImage: `url(${contact})` }}>
      <div className='container-lg'>
        <h2 className='mb-5'>Contact Us</h2>
        <div className='row justify-content-center my-5'>
          <div className='col-lg-6'>
            <form onSubmit={sendEmail}>
              <div className='mb-3'>
                <label htmlFor='user_name'>Name & Surname</label>
                <input type='text' className='form-control' id='user_name' name='user_name' required />
              </div>
              <div className='mb-3'>
                <label htmlFor='user_email'>Email</label>
                <input type='email' className='form-control' id='user_email' name='user_email' required />
              </div>
              <div className='mb-3'>
                <label htmlFor='message'>Message</label>
                <textarea className='form-control' id='message' name='message' rows='5' required></textarea>
              </div>
              <div className='mb-4 text-center'>
                <button type='submit' className='btn btn-primary'>Send</button>
              </div>
              {successMessage && <div className="text-success">{successMessage}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
