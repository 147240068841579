// Footer.js
import React, { useState } from 'react';
import "./Footer.css";

function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const toggleModal = (event, content) => {
    event.preventDefault();
    setShowModal(!showModal);
    setModalContent(content);
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-sm-2">
            <p className="text-center mb-0"><a href="#" onClick={(e) => toggleModal(e, "terms")}>Terms of Service</a> | <a href="#" onClick={(e) => toggleModal(e, "user-agreement")}>User Agreement</a></p>
          </div>
          <div className="col-md-6 order-sm-1">
            <p className="text-center mb-0">Built by HAILE Pty Ltd</p>
          </div>
        </div>
      </div>

      {showModal && (
        <div className='modal-overlay' onClick={toggleModal}>
          <div className='modal-body'>
            {modalContent === "terms" && (
              <>
                <h2>Terms of Service</h2>
                <div className="terms-content">
                  <p>Welcome to Panteon!</p>
                  <p>These Terms of Service ("Terms") govern your access to and use of Panteon's services, including our website, mobile applications, and any other services offered by Panteon (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms.</p>
                  <p>1. <strong>Acceptance of Terms:</strong> By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our Services.</p>
                  <p>2. <strong>Description of Services:</strong> Panteon provides a platform for users to access information about our products, place orders, and engage with our brand. Our Services may include, but are not limited to, our website, mobile applications, and any other features, content, or applications offered by Panteon.</p>
                  <p>3. <strong>User Accounts:</strong> Some features of our Services may require you to create a user account. You agree to provide accurate and complete information when creating your account and to keep your login credentials secure. You are responsible for all activity that occurs under your account.</p>
                  <p>4. <strong>Intellectual Property:</strong> All content, trademarks, logos, and other intellectual property displayed on or made available through our Services are the property of Panteon or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
                  <p>5. <strong>Prohibited Conduct:</strong> You agree not to engage in any of the following prohibited activities: (a) Use our Services for any unlawful purpose; (b) Impersonate any person or entity, or falsely state or otherwise misrepresent yourself or your affiliation with any person or entity; (c) Attempt to gain unauthorized access to our Services or any other user accounts; (d) Use our Services to transmit any viruses, worms, or other harmful code; (e) Interfere with or disrupt the operation of our Services or any servers or networks connected to our Services.</p>
                  <p>6. <strong>Limitation of Liability:</strong> To the fullest extent permitted by applicable law, in no event shall Panteon or its affiliates, officers, directors, employees, agents, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the Services; (b) any conduct or content of any third party on the Services.</p>
                  <p>7. <strong>Changes to Terms:</strong> We may update these Terms from time to time. Any changes we make to these Terms will be effective immediately upon posting the updated Terms on our website. Your continued use of our Services after any such changes constitutes your acceptance of the new Terms.</p>
                  <p>8. <strong>Contact Us:</strong> If you have any questions about these Terms, please contact us at:</p>
                  <address>
                    Panteon<br />
                    123 Main Street<br />
                    City, State, ZIP<br />
                    Email: info@panteon.com<br />
                    Phone: 123-456-7890<br />
                  </address>
                </div>
              </>
            )}

            {modalContent === "user-agreement" && (
              <>
                <h2>User Agreement</h2>
                <div className="terms-content">
                  <p>Welcome to Panteon!</p>
                  <p>This User Agreement ("Agreement") governs your access to and use of Panteon's services, including our website, mobile applications, and any other services offered by Panteon (collectively, the "Services"). By accessing or using our Services, you agree to be bound by this Agreement.</p>
                  <p>1. <strong>Acceptance of Agreement:</strong> By accessing or using our Services, you agree to be bound by this Agreement. If you do not agree to this Agreement, you may not use our Services.</p>
                  <p>2. <strong>Use of Services:</strong> You agree to use our Services only for purposes that are permitted by this Agreement and any applicable laws and regulations. You agree not to engage in any activity that interferes with or disrupts our Services.</p>
                  <p>3. <strong>Account Registration:</strong> Some features of our Services may require you to create a user account. You agree to provide accurate and complete information when creating your account and to keep your login credentials secure. You are responsible for all activity that occurs under your account.</p>
                  <p>4. <strong>Intellectual Property:</strong> All content, trademarks, logos, and other intellectual property displayed on or made available through our Services are the property of Panteon or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>
                  <p>5. <strong>Prohibited Activities:</strong> You agree not to engage in any of the following prohibited activities: (a) Use our Services for any unlawful purpose; (b) Impersonate any person or entity, or falsely state or otherwise misrepresent yourself or your affiliation with any person or entity; (c) Attempt to gain unauthorized access to our Services or any other user accounts; (d) Use our Services to transmit any viruses, worms, or other harmful code; (e) Interfere with or disrupt the operation of our Services or any servers or networks connected to our Services.</p>
                  <p>6. <strong>Limitation of Liability:</strong> To the fullest extent permitted by applicable law, in no event shall Panteon or its affiliates, officers, directors, employees, agents, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the Services; (b) any conduct or content of any third party on the Services.</p>
                  <p>7. <strong>Changes to Agreement:</strong> We may update this Agreement from time to time. Any changes we make to this Agreement will be effective immediately upon posting the updated Agreement on our website. Your continued use of our Services after any such changes constitutes your acceptance of the new Agreement.</p>
                  <p>8. <strong>Contact Us:</strong> If you have any questions about this Agreement, please contact us at:</p>
                  <address>
                    Panteon<br />
                    123 Main Street<br />
                    City, State, ZIP<br />
                    Email: info@panteon.com<br />
                    Phone: 123-456-7890<br />
                  </address>
                </div>
              </>
            )}
            <button className='btn btn-secondary' onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}
    </footer>
  )
}

export default Footer;
