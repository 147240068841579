// Home.js
import React, { useState } from 'react';
import './Home.css';
import contact from "./Contact-bg.jpg";
import logo from "./Logo.png";

function Home() {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <section className="hero-section" style={{ backgroundImage: `url(${contact})`, overflow: 'hidden' }}>
      <div className="container">
        <div className="row">
          <div className="col text-center text-white">
            <img src={logo} alt="logo" className="logo" />
            <h2 id='home-paragraph' className="mb-4">YOUR ULTIMATE DESTINATION <br />FOR COMPREHENSIVE PLUMBING<br /> AND SANITARY SOLUTIONS</h2>
            <button id='contact-button' onClick={toggleModal}>CONTACT US</button>
          </div>
        </div>
      </div>

      {showModal && (
        <div className='modal-overlay' onClick={toggleModal}>
          <div className='modal-body'>
            <h2>Contact Details</h2>
            <p>Email: pantheongroup2023@gmail.com</p>
            <p>Phone: +27 63 573 9510 | +27 76 279 3755 </p>
            <button className='btn btn-secondary' onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Home;  
